import apiClient from '../apiClient';

// Retrieve User Profile
export async function retrieveProfile() {
  try {
    const request = await apiClient.get('/profile');
    return request;
  } catch (error) {
    return error.request;
  }
}

// Update User Profile
export async function updateProfile(data) {
  try {
    const request = await apiClient.put('/profile/update', data);
    return request;
  } catch (error) {
    return error.request;
  }
}

// Get User Tokens
export async function getTokens() {
  try {
    const request = await apiClient.get('/token');
    return request;
  } catch (error) {
    return error.request;
  }
}

// Create Token
export async function createToken(formData) {
  try {
    const request = await apiClient.post('/token/register', formData);
    return request;
  } catch (error) {
    return error.request;
  }
}

// Remove Token
export async function removeToken(formData) {
  try {
    const request = await apiClient.delete(`/token/remove`, { data: formData });
    return request;
  } catch (error) {
    return error.request;
  }
}
